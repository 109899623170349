import {
  FormControlLabel as MuiFormControlLabel,
  InputAdornment as MuiInputAdornment,
  RadioGroup as MuiRadioGroup,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'

export const RadioGroup = styled(MuiRadioGroup)`
  && {
    flex-wrap: nowrap;
    height: 35px;
  }
`

export const FormControlLabel = styled(MuiFormControlLabel)`
  && {
    border: 1px solid ${({ theme }) => theme.colors.coolGray.cool250};
    margin: 0;
    overflow: hidden;
    width: 100px;
  }

  @media (min-width: 375px) {
    && {
      width: 119px;
    }
  }

  &&:hover {
    background-color: ${({ theme }) => theme.colors.coolGray.cool100};
  }

  &&:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &&:last-of-type {
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  && .MuiRadio-root {
    padding: 9px 6px 9px;
  }

  &&.Mui-disabled {
    // Border color matches the old palette from the standalone cart app
    // and should be unified in later refactors.
    border-color: rgba(0, 0, 0, 0.26);
  }

  && .MuiRadio-colorSecondary {
    background-color: transparent;
  }

  && .MuiFormControlLabel-label {
    font-size: 0.75rem;
    font-weight: 500;
  }

  &&.selected {
    background-color: ${({ theme }) => theme.colors.coolGray.cool150};

    & .MuiRadio-colorSecondary.Mui-checked:hover {
      background-color: ${({ theme }) => theme.colors.coolGray.cool150};
    }
  }

  && .MuiSvgIcon-root {
    height: 17px;
    width: 17px;
  }
`

export const TextField = styled(MuiTextField)`
  && .MuiSelect-root {
    padding-bottom: 0;
    padding-top: 0;
    height: 35px;
  }

  && .MuiInputBase-root:hover {
    background-color: ${({ theme }) => theme.colors.coolGray.cool100};
  }
`

export const InputAdornment = styled(MuiInputAdornment)`
  && {
    flex-shrink: 0;
  }
`

export const ErrorMessage = styled(Typography)`
  font-size: 0.75rem;
  margin-bottom: 10px;
  margin-top: -5px;
  color: ${({ theme }) => theme.colors.semantic.error};
`
