import { Dropdown } from '@designsforhealth/dfh-react-components'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { GetCustomerDispensaryPromosQuery } from '../../../graphql/camel'
import { isNotNull } from '../../../utils/collectionTools'
import { monthDayYear } from '../../../utils/dateTools'

export interface DispensaryPromoSelectorContentsProps {
  data: GetCustomerDispensaryPromosQuery
  onSelectPromoCode: (promoCode: string) => void
  promoCode: string
}

const DispensaryPromoSelectorContents: React.VFC<DispensaryPromoSelectorContentsProps> = ({
  data,
  onSelectPromoCode,
  promoCode,
}) => {
  const activePromos = useMemo(
    () =>
      data.getCustomerDispensaryPromos?.promos.filter(isNotNull).filter((promo) => !promo.inactive),
    [data.getCustomerDispensaryPromos?.promos]
  )

  const options = useMemo(
    () =>
      activePromos?.map(({ dispensaryCode, rootPromoName, expirationDate }) => ({
        label: `${dispensaryCode} - ${rootPromoName} ${
          expirationDate ? `- Expiration: ${monthDayYear(expirationDate)}` : ''
        }`,
        value: dispensaryCode,
      })),
    [activePromos]
  )

  const defaultOption = useMemo(() => {
    return options?.find(({ value }) => value === promoCode)
  }, [options, promoCode])

  const { control, watch } = useForm<{
    promo: {
      label: string
      value: string
    }
  }>({
    defaultValues: {
      promo: defaultOption,
    },
  })

  const formPromo = watch('promo')

  useEffect(() => {
    if (!options) {
      return
    }
    if (!formPromo) {
      onSelectPromoCode('')
      return
    }
    if (formPromo.value !== promoCode) {
      onSelectPromoCode(formPromo.value)
    }
  }, [defaultOption, formPromo, onSelectPromoCode, options, promoCode])

  return (
    <Dropdown
      isClearable={true}
      required={false}
      control={control}
      id="promo"
      name="promo"
      options={options ?? []}
      prompt={'Promo Code:'}
      defaultValue={defaultOption}
      placeholder="(None)"
    />
  )
}

export default DispensaryPromoSelectorContents
